.user-container {
    height: 30px;
    margin-right: 15px;
    border-right: 1px solid gray;
}

.user-container > img {
    width: 25px;
}

.user-container > span {
    color: gray;
    vertical-align: top;
    margin-right: 15px;
}