.modal-modal-title-div {
    margin: auto;
    height: 50px;
    border-radius: 10px;
}

.modal-modal-title-text {
    display: flex;
    justify-content: center;
    color: white;
    font-size: 24px;
}

.modal-modal-title-text > span {
    margin-top: 5px;
    margin-left: 15px;
    margin-right: 15px;
}

.modal-modal-body-div {
    margin-top: 25px;
}

.modal-close-button-div {
    text-align: end;
    margin-top: 0;
    margin-right: 10px;
}

.modal-close-button {
    width: 20px;
    margin: 0;
    padding: 0;
    border: none;
    background-color: white;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
}

.modal-close-button > img {
    width: 100%;
}