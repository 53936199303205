.change-term-data-content {
    display: flex;
    justify-content: space-around;
}

.change-term-data-fields {
    margin-right: 10px;
    margin-left: 10px;
}

.change-term-data-field {
    margin-left: 1px;
    margin-bottom: 10px;
    max-width: 170px;
}

.change-term-data-label {
    font-size: 12px;
    font-weight: bold;
}

.change-term-data-field > input {
    margin-top: 3.5px;
}

.change-term-data-field-error-text {
    font-size: 12px;
    color: red;
}

.change-term-data-activ-label {
    margin-left: 5px;
    font-size: 15px;
    font-weight: 600;
}

.change-term-data-buttons {
    display: flex;
    margin-top: 20px;
    margin-left: 10px;
}