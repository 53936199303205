@keyframes ldio {
    0% { opacity: 1 }
    100% { opacity: 0 }
}
.ldio div {
    left: 50%;
    top: 0;
    position: absolute;
    animation: ldio linear 1s infinite;
    background: blue;
    width: 12px;
    height: 24px;
    border-radius: 6px / 12px;
    transform-origin: 6px 52px;
}.ldio div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.9166666666666666s;
    background: blue;
}.ldio div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -0.8333333333333334s;
    background: blue;
}.ldio div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.75s;
    background: blue;
}.ldio div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.6666666666666666s;
    background: blue;
}.ldio div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.5833333333333334s;
    background: blue;
}.ldio div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.5s;
    background: blue;
}.ldio div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.4166666666666667s;
    background: blue;
}.ldio div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.3333333333333333s;
    background: blue;
}.ldio div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.25s;
    background: blue;
}.ldio div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.16666666666666666s;
    background: blue;
}.ldio div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.08333333333333333s;
    background: blue;
}.ldio div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
    background: blue;
}
.preloader {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 100000;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.7);
}
.loader {
    width: 100px;
    height: 100px;
    animation: rotate 1s linear infinite;
}
.ldio {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
}
.ldio div { box-sizing: content-box; }  