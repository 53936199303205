.success-modal-content {
    margin-top: 15px;
    display: flex;
    justify-content: center;
}
.success-modal-circle {
    stroke-dasharray: 1194;
    stroke-dashoffset: 1194;
    animation: dc 1.5s ease-in-out;
    animation-fill-mode: forwards;
}
.success-modal-tick {
    stroke-dasharray: 350;
    stroke-dashoffset: 350;
    animation: dt 1.3s ease-out;
    animation-fill-mode: forwards;
    animation-delay: 1.45s;
}
@keyframes dc {
    from {
        stroke-dashoffset: 1194;
    }
    to {
        stroke-dashoffset: 2388;
    }
}
@keyframes dt {
    from {
        stroke-dashoffset: 350;
    }
    to {
        stroke-dashoffset: 0;
    }
}
  