.language-container {
    display: flex;
}

.button-language {
    margin-right: 10px;
    border: 1px solid blue;
    background-color: white;
    color: blue;
}

.active {
    background-color: blue;
    color: white;
}