.transactions-search-inputs {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
}

.transactions-search-input-fields {
    display: flex;
    margin-top: 13px;
}

.transactions-search-calendar-fields {
    display: flex;
    /* margin-top: 23px; */
    margin-left: 15px;
}

.transactions-search-date {
    margin-right: 10px;
}

.transactions-search-date > span {
    display: block;
    font-size: 14px;
    margin-bottom: 3px;
}

.react-datepicker-popper {
    z-index: 10 !important;
}

.transactions-search-buttons {
    display: flex;
    margin-bottom: 30px;
}