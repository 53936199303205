.add-term-data-content {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.add-term-data-fields {
    margin-right: 10px;
    margin-left: 10px;
}

.add-term-data-buttons {
    display: flex;
    margin-top: 30px;
    margin-left: 10px;
}