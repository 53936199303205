.terminals-page-search-area {
    display: flex;
    justify-content: space-between;
    height: 80px;
    /* margin-top: 50px; */
}

.terminals-page-search-export-content {
    display: flex;
}

.terminals-page-search-form {
    display: flex;
}

.terminals-page-search-btn,
.terminals-page-export-btn {
    margin-left: 20px;
    margin-top: 24px;
}

.terminals-page-export-btn {
    margin-left: 10px;
}